export function ticketStatus() {
  return {
    0: '未付費',
    1: '已付費',
    2: '催繳',
    3: '免付費',
    4: '非正常',
  }
}

export function statusColor() {
  return {
    未付費: 'error',
    已付費: 'success',
    催繳: 'warning',
    免付費: 'info',
    非正常: 'primary',
  }
}

export function issuedBy() {
  return {
    1: 'K0001',
    2: 'K0002',
    3: 'K0003',
  }
}
export function road() {
//   return {
//     19006: {
//       code: '001',
//       section: '民權路-伯玉路一段80巷',
//       road: '民生路',
//     },
//     19083: {
//       code: '001',
//       section: '民權路-伯玉路一段80巷',
//       road: '民生路',
//     },

  return {
    18995: '珠浦北路',
    18996: '珠浦北路',
    18997: '珠浦北路',
    18998: '珠浦北路',
    18999: '珠浦北路',
    19000: '珠浦北路',
    19001: '珠浦北路',
    19002: '珠浦北路',
    19003: '珠浦北路',
    19004: '珠浦北路',
    19005: '珠浦北路',
    19006: '民生路',
    19007: '民生路',
    19008: '民生路',
    19009: '民生路',
    19010: '民生路',
    19011: '民生路',
    19012: '民生路',
    19013: '民生路',
    19014: '民生路',
    19015: '民生路',
    19016: '民生路',
    19017: '民生路',
    19018: '民生路',
    19019: '民生路',
    19020: '民生路',
    19021: '民生路',
    19022: '民生路',
    19023: '民生路',
    19024: '民生路',
    19025: '民生路',
    19026: '民生路',
    19027: '民生路',
    19028: '民生路',
    19029: '民生路',
    19030: '民生路',
    19031: '浯江北堤路',
    19032: '浯江北堤路',
    19033: '浯江北堤路',
    19034: '浯江北堤路',
    19035: '浯江北堤路',
    19036: '浯江北堤路',
    19037: '浯江北堤路',
    19038: '浯江北堤路',
    19039: '浯江北堤路',
    19040: '浯江北堤路',
    19041: '浯江北堤路',
    19042: '浯江北堤路',
    19043: '浯江北堤路',
    19044: '浯江北堤路',
    19045: '浯江北堤路',
    19046: '浯江北堤路',
    19047: '浯江北堤路',
    19048: '浯江北堤路',
    19049: '浯江北堤路',
    19050: '浯江北堤路',
    19051: '浯江北堤路',
    19052: '浯江北堤路',
    19053: '浯江北堤路',
    19054: '浯江北堤路',
    19055: '浯江北堤路',
    19056: '浯江北堤路',
    19057: '浯江北堤路',
    19058: '浯江北堤路',
    19059: '浯江北堤路',
    19060: '浯江北堤路',
    19061: '浯江北堤路',
    19062: '浯江北堤路',
    19063: '浯江北堤路',
    19064: '浯江北堤路',
    19065: '浯江北堤路',
    19066: '浯江北堤路',
    19067: '浯江北堤路',
    19068: '浯江北堤路',
    19069: '浯江北堤路',
    19070: '浯江北堤路',
    19071: '浯江北堤路',
    19072: '浯江北堤路',
    19073: '浯江北堤路',
    19074: '浯江北堤路',
    19075: '浯江北堤路',
    19076: '浯江北堤路',
    19077: '浯江北堤路',
    19078: '浯江北堤路',
    19079: '浯江北堤路',
    19080: '浯江北堤路',
    19081: '浯江北堤路',
    19082: '浯江北堤路',
    19083: '民生路54巷',
    19084: '民生路54巷',
    19085: '民生路54巷',
    19086: '民生路54巷',
    19087: '民生路54巷',
    19088: '民生路54巷',
    19089: '民生路54巷',
  }
}
