import request from '@/utils/request'

export function getTicketRecords(page, token) {
  return request({
    url: '/tickets',
    method: 'get',
    params: {
      page
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
export function getTicketRecordsByPlate(plate, token) {
  return request({
    url: '/tickets',
    method: 'get',
    params: {
      plate
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
export function getTicketRecordsByCode(code, token) {
  return request({
    url: '/tickets',
    method: 'get',
    params: {
      code
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
